import React, { useEffect } from 'react'
import { motion, useAnimation, } from "framer-motion"
import { getMaskStaticPathWithAspectRatio } from "../../utils/staticPathsForMaskImages"


const ImageWithMaskAnimation = ({
    srcImg,
    imgMaskName = "mask11",
    // diameter = 100,
    // pointsNumber = 10,
    height = "inherit",
    isAnimation = true,
}) => {

    const ctrlImg = useAnimation()
    // const dOuterCircle = svgCirclePathDRelativeToCenter({ diameter, outerCirclePercentage })
    // const dsCirclesAround = svgPathsDRelativeToCenterOfCirclesAround({ diameter, pointsNumber, distanceAroundCircleFromCenterPercentage, radiusAroundCircle })

    useEffect(() => {
        let isMounted = true
        if (!isAnimation) return
        const seq1 = async () => {

            // ctrlImg.start(() => ({
            //     // fillOpacity: 0,
            //     backgroundSize: "100%",
            //     transition: {
            //         // type: "spring", stiffness: 100,
            //         duration: 2,
            //         ease: "easeInOut",
            //         delay: pointsNumber + 2

            //     }
            // }))
            // ctrlImg.start(() => ({

            //     backgroundImage: 'radial-gradient(circle at 50% 50%, #580303 0%, #530101 25%, transparent 75%, transparent 100%)',
            //     transition: {
            //         // type: "spring", stiffness: 100,
            //         duration: 0.5,
            //         ease: "easeInOut",
            //         delay: pointsNumber + 2

            //     }
            // }))
            isMounted && ctrlImg.start(() => ({

                // maskPosition: "50%",
                maskSize: "100% 100%",

                transition: {
                    // type: "spring", stiffness: 100,
                    duration: 1,
                    ease: "easeInOut",
                    // delay: 0.5

                }
            }))
        }
        seq1()
        return () => {
            isMounted = false
            ctrlImg.stop()
        }
    }, [ isAnimation, ctrlImg ])

    const staticMaskImgPath = getMaskStaticPathWithAspectRatio(imgMaskName).path

    // const { dOuterCircle, dsCirclesAround } = svgPathsRelativeToCenterOfOuterCircleAndSmallCirclesAround()
    // const dOuterCircleStart = svgCirclePathDRelativeToCenter({ diameter, outerCirclePercentage: 0 })
    // const dsCirclesAroundStart = svgPathsDRelativeToCenterOfCirclesAround({ diameter, pointsNumber, distanceAroundCircleFromCenterPercentage: 0, radiusAroundCircle: 0 })
    // console.log("dOuterCircle", dOuterCircle)
    // console.log("dsCirclesAround", dsCirclesAround)
    // const mask = isImgMaskRadialGradient ? 'radial-gradient(circle at 50% 50%, black 0%, black 25%, transparent 75%, transparent 100%)' : "unset"
    // console.log("isMaskRadialGradient", isImgMaskRadialGradient)
    // console.log("mask", mask)
    return (
        // <div style={{
        //     position: 'relative',
        // }}>
        <motion.div
            animate={ctrlImg}

            src={srcImg}
            // width="100%"
            // height="100%"
            style={{
                // position: 'absolute',
                // objectFit: 'cover',
                // height: "100%",
                backgroundImage: `url(${srcImg})`,
                height: height,
                // width: '100%',
                WebkitMaskImage: `url(${staticMaskImgPath})`,
                maskImage: `url(${staticMaskImgPath})`,
                maskSize: isAnimation ? '20% 100%' : '100% 100%',
                backgroundRepeat: "repeat-x",
                backgroundPosition: "center",
                maskPosition: '50%',
                // maskImage: mask, //'radial-gradient(circle at 50% 50%, black 0%, black 25%, transparent 75%, transparent 100%)', // Apply the mask here
                // // WebkitMaskImage: 'radial-gradient(circle at 50% 50%, transparent 0%, transparent 25%, black 75%, black 100%)', // For Safari
                // maskPosition: '0%',
                // backgroundSize: '1%',
                // backgroundAttachment: 'fixed',
            }
            }
        />
    )
}
export default ImageWithMaskAnimation
